<script setup lang="ts">
import type { Header, Link, NavLink } from "~/types/prismic.js";
import headerQuery from "~/gql/headerQuery.gql";

const route = useRoute();
const navOpen = ref(false);
const nav = ref<null | HTMLElement>(null);
const header = ref<null | HTMLElement>(null);

const { data } = await useAsyncQuery<{ header?: Header }>(headerQuery);

const content = computed(() => data.value?.header);

const activePath = (link: Link) => {
  if (!link._meta) return false;
  const linkPath = link._meta.uid === "index" ? "/" : `/${link._meta.uid}`;
  return route.path === linkPath;
};

const hasChildren = (navLink: NavLink) =>
  !!navLink.primary_sub_link
  || !!navLink.sub_links?.length
  || !!navLink.icon_sub_links?.length;
</script>

<template>
  <Transition
    name="fade"
    mode="out-in"
    appear
  >
    <header
      v-if="content"
      ref="header"
    >
      <MarginContainer>
        <NuxtLink to="/">
          <NuxtImg
            v-if="content.logo"
            class="tablet-up"
            :src="content.logo.url"
          />
          <NuxtImg
            v-if="content.mobile_logo"
            class="mobile-only"
            :src="content.mobile_logo.url"
          />
        </NuxtLink>
        <nav
          ref="nav"
          :class="{ open: navOpen }"
          @click="navOpen = !navOpen"
        >
          <AppNavButton :open="navOpen" />
          <div
            v-for="(navLink, index) in (content.navigation_links || [])
              .map((e) => e.navigation_link)
              .filter(Boolean)"
            :key="`nav${index}`"
            :class="{ primary: navLink.primary }"
          >
            <PrismicLink
              :link="navLink.link"
              :class="{
                active: activePath(navLink.link),
              }"
            >
              <span>{{ navLink.text }}</span>
              <span v-if="hasChildren(navLink)"><Icon
                name="icons:dropdown-arrow"
                :size="24"
              /></span>
            </PrismicLink>
            <AppDropdownButton v-if="hasChildren(navLink)" />
            <div
              v-if="hasChildren(navLink)"
              :class="{ 'has-primary': navLink.primary_sub_link }"
            >
              <PrismicLink
                v-if="navLink.primary_sub_link"
                :link="navLink.primary_sub_link.link"
                class="primary"
              >
                <span>{{ navLink.primary_sub_link.text }}</span>
              </PrismicLink>
              <div
                v-for="(subNavLink, subIndex) in (navLink.sub_links || [])
                  .map((e) => e.sub_link)
                  .filter(Boolean)"
                :key="`subNav${index}${subIndex}`"
              >
                <PrismicLink
                  :link="subNavLink.link"
                  :class="{
                    active: activePath(subNavLink.link),
                  }"
                >
                  <span>{{ subNavLink.text }}</span>
                </PrismicLink>
              </div>
              <div
                v-if="navLink.icon_sub_links"
                class="icon-links"
              >
                <PrismicLink
                  v-for="(subNavLink, subIndex) in (
                    navLink.icon_sub_links || []
                  )
                    .map((e) => e.sub_link)
                    .filter(Boolean)"
                  :key="`iconSubNav${index}${subIndex}`"
                  :link="subNavLink.link"
                >
                  <NuxtImg :src="subNavLink.icon ? subNavLink.icon.url : ''" />
                  <span>{{ subNavLink.text }}</span>
                </PrismicLink>
              </div>
            </div>
          </div>
        </nav>
      </MarginContainer>
    </header>
  </Transition>
</template>

<style lang="scss" scoped>
header {
  width: 100%;
  padding: 24px 0;
  top: -100px; // This allows a transition
  transition: top 300ms, background-color 300ms;
  background-color: rgba($white1, 0);
  z-index: 10;

  section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
    background-color: transparent;

    @include tablet {
      align-items: flex-start;
    }

    img {
      height: 32px;

      @include mobile {
        height: 24px;
      }
    }

    > a {
      margin-right: 40px;
    }
  }

  nav {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    width: 100%;

    @include tablet {
      justify-content: flex-end;
    }

    > .wrapper {
      @include desktop-only {
        display: none;
      }
    }

    &.open {
      @include tablet {
        position: fixed;
        background-color: $blue4;
        width: 100%;
        height: 100%;
        padding: 40px;
        top: 0;
        left: 0;
        flex-direction: column;
        justify-content: flex-start;
        box-sizing: border-box;
      }

      @include mobile {
        padding: 16px;
      }

      > .wrapper {
        @include tablet {
          position: absolute;
          top: 24px;
          right: 40px;
        }

        @include mobile {
          right: 16px;
        }
      }

      > div {
        @include tablet {
          display: flex;
        }
      }
    }

    > div {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      @include tablet {
        margin: 12px 0;
        display: none;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        cursor: pointer;
      }

      &.primary {
        @include desktop-only {
          > .prismic-link {
            background-color: $blue2;
            color: $white1;
            padding: 16px 24px;
            border-radius: 2px;

            .wrapper {
              color: $white1;
            }
          }
        }
      }

      .prismic-link {
        font-family: $font-secondary;
        font-size: rem-calc(18px);
        font-weight: 700;
        text-decoration: none;
        color: $blue1;
        text-align: center;

        @include tablet {
          font-family: $font-primary;
          font-weight: 600;
          font-size: rem-calc(25.6px);
          text-align: left;
          margin: 0;
          flex: 1;
        }

        .iconify {
          vertical-align: middle;
          transition: transform 500ms;
          color: $blue1;

          @include tablet {
            display: none;
          }
        }
      }

      > .wrapper {
        @include desktop-only {
          display: none;
        }
      }

      > div {
        display: none;
        position: absolute;
        top: 100%;
        width: max-content;
        padding: 8px 0;
        padding-top: 16px;
        cursor: pointer;

        @include tablet {
          position: static;
          padding: 0;
          width: 100%;
        }

        &::before {
          @include desktop-only {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            top: 7px;
            right: calc(50% - 10px);
            border: 10px solid transparent;
            border-top: none;
            border-bottom-color: $white1;
            filter: drop-shadow(0 -1px 0 $blue3);
          }
        }

        &.has-primary::before {
          border-bottom-color: $blue4;
        }

        > div,
        > a {
          display: block;
          padding: 8px 40px;
          background-color: $white1;
          text-align: center;
          border-left: 1px solid $blue3;
          border-right: 1px solid $blue3;

          @include tablet {
            text-align: initial;
            padding: 0;
            background-color: transparent;
            border: none;
          }

          &:first-child {
            padding-top: 40px;
            border-top: 1px solid $blue3;
            border-radius: 2px 2px 0 0;

            @include tablet {
              padding-top: 8px;
              border: none;
            }
          }

          &:last-child {
            border-bottom: 2px solid $blue2;
            border-radius: 0 0 2px 2px;
            padding-bottom: 40px;

            @include tablet {
              border-bottom: 0 none;
              padding-bottom: 16px;
            }
          }

          &:first-child:last-child {
            border-radius: 2px;
          }

          &.icon-links {
            @include desktop-only {
              display: grid;
              grid-template-columns: repeat(3, minmax(0, 1fr));
              gap: 16px;
              padding-top: 16px;
              padding-bottom: 32px;
            }

            .prismic-link {
              @include desktop-only {
                width: 200px;
                display: flex;
                gap: 8px;
                align-items: center;
                text-align: left;
              }

              img {
                width: 40px;
                height: 40px;
                background-color: $blue4;
                border-radius: 4px;

                @include tablet {
                  display: none;
                }
              }
            }
          }
        }

        .prismic-link {
          @include tablet {
            padding: 8px 20px;
            font-size: rem-calc(25.6px);
            font-weight: 500;
            display: block;
          }

          &.primary {
            @include desktop-only {
              background-color: $blue4;
              color: $blue2;
              text-decoration: underline;
              padding-bottom: 16px;
              text-align: left;
            }
          }
        }
      }

      &:hover {
        > div {
          display: block;
        }

        .wrapper {
          transform: rotateX(180deg);
        }
      }
    }
  }
}
</style>
