<script setup lang="ts">
import type { NuxtError } from "#app";

const props = defineProps<{
  error: NuxtError;
}>();

useHead({
  title: props.error.message,
  titleTemplate: (title?: string) => (title ? `${title} | CALF` : "CALF"),
});
</script>

<template>
  <AppHeader />
  <main>
    <MarginContainer>
      <h1>{{ error.statusCode }}</h1>
      <div>
        <template v-if="error.statusCode === 404">
          We are sorry, the page you are looking for cannot be found.
        </template>
        <template v-else>
          Sorry, something seems to have gone wrong, please try again
          later.
        </template>
      </div>

      
    </MarginContainer>
  </main>
  <AppFooter />
</template>
