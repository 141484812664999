import revive_payload_client_4sVQNw7RlN from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/opt/build/repo/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_WLsn00x1qh from "/opt/build/repo/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_client_Fdvg3o8568 from "/opt/build/repo/node_modules/@nuxtjs/web-vitals/dist/runtime/plugin.client.mjs";
import titles_dw2T9lEw4h from "/opt/build/repo/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import siteConfig_izaWKZ8rEu from "/opt/build/repo/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_Uk9bcXDHeN from "/opt/build/repo/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import plugin_tbFNToZNim from "/opt/build/repo/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_eTVJQYlCmx from "/opt/build/repo/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import prismic_apollo_link_bNvK4RmKg9 from "/opt/build/repo/plugins/prismic-apollo-link.ts";
import vue3_touch_events_3JrctwDJKG from "/opt/build/repo/plugins/vue3-touch-events.ts";
import defaults_aRl2YLnTcb from "/opt/build/repo/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_WLsn00x1qh,
  plugin_client_Fdvg3o8568,
  titles_dw2T9lEw4h,
  siteConfig_izaWKZ8rEu,
  inferSeoMetaPlugin_Uk9bcXDHeN,
  plugin_tbFNToZNim,
  plugin_eTVJQYlCmx,
  prismic_apollo_link_bNvK4RmKg9,
  vue3_touch_events_3JrctwDJKG,
  defaults_aRl2YLnTcb
]