import { setContext } from "@apollo/client/link/context";
import { createPrismicLink } from "apollo-link-prismic";
import Cookies from "js-cookie";

export default defineNuxtPlugin(() => {
  const nuxtApp = useNuxtApp();

  const runtimeConfig = useRuntimeConfig();

  // const prismicRef = import.meta.client
  //   ? Cookies.get("io.prismic.previewSession")
  //   : /:([^/]+)\?/.exec(useCookie("io.prismic.preview").value?.[`${runtimeConfig.public.prismicRepo}.prismic.io`]?.preview ?? "")?.[1];

  const link = createPrismicLink({
    repositoryName: runtimeConfig.public.prismicRepo,
    accessToken: runtimeConfig.public.prismicAccessToken,
  });

  const previewLink = setContext((_, { headers }) => {
    if (import.meta.client) {
      headers["Prismic-ref"] = Cookies.get("io.prismic.previewSession");
    }

    return { headers };
  });

  nuxtApp.$apollo.clients.default.setLink(previewLink.concat(link));
});
