<script setup lang="ts">
defineProps<{
  disabled?: boolean;
  required?: boolean;
}>();

const emit = defineEmits(["click"]);
</script>

<template>
  <button
    :disabled="disabled"
    :required="required"
    @click="() => emit('click')"
  >
    <slot />
  </button>
</template>

<style lang="scss" scoped>
button {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  font-size: rem-calc(18px);
  font-family: $font-secondary;
  font-weight: 700;
  border: 0 none;
  border-radius: 2px;
  background-color: $blue2;
  padding: 16px 38px;
  text-align: center;
  cursor: pointer;
  color: $white1;

  @include mobile {
    padding: 16px;
    width: 100%;
  }

  &.secondary {
    color: $blue1;
    background-color: $blue4;
    border: 1px solid $blue2;
    border-radius: 2px;
  }

  &[disabled] {
    background-color: $grey1;
  }
}
</style>
