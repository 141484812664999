<script setup lang="ts">
defineProps<{
  open?: boolean;
}>();

const emit = defineEmits(["click"]);
</script>

<template>
  <span
    class="wrapper"
    :class="{ open }"
  >
    <Icon
      name="icons:dropdown-arrow"
      :size="24"
      @click="emit('click')"
    />
  </span>
</template>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  border: 1px solid $blue2;
  border-radius: 2px;
  background-color: $blue4;
  vertical-align: middle;
  cursor: pointer;
  transition: background-color 300ms;

  span {
    color: $blue2;
    transform-origin: center;
    transition: transform 300ms;
  }

  &.open {
    background-color: $blue2;

    span {
      transform: rotateX(180deg);
      color: $white1;
    }
  }
}
</style>
