<script setup lang="ts">
defineProps<{
  open?: boolean;
}>();

const emit = defineEmits(["click"]);
</script>

<template>
  <span
    class="wrapper"
    :class="{ open }"
  >
    <Icon
      :name="`icons:${open ? 'close' : 'hamburger'}`"
      :size="32"
      @click="emit('click')"
    />
  </span>
</template>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  width: fit-content;
  border: 1px solid $blue2;
  border-radius: 2px;
  background-color: $blue4;
  transition: background-color 500ms;

  span {
    cursor: pointer;
    color: $blue2;
    transform-origin: center;
    transition: transform 500ms;
  }

  &.open {
    background-color: $blue2;

    span {
      color: $white1;
      transform: rotateZ(180deg);
    }
  }
}
</style>
