<script setup lang="ts">
import Cookies from "js-cookie";

const showBar = ref(false);

const closeBar = () => {
  Cookies.set("accept_cookie", "1");

  showBar.value = false;
};

onMounted(() => showBar.value = !!Cookies.get("accept_cookie"));
</script>

<template>
  <div v-if="showBar">
    <span @click="closeBar">&times;</span>
    We collect cookies in order to improve your experience.
    <a href="https://tools.google.com/dlpage/gaoptout?hl=en">Click here</a> if
    you would not like to receive these cookies.
  </div>
</template>

<style lang="scss" scoped>
div {
  background-color: $blue2;
  color: $white1;
  position: fixed;
  bottom: 0;
  padding: 16px 40px 16px 16px;
  width: 100%;
  text-align: center;
  z-index: 10;
  box-sizing: border-box;
  font-size: rem-calc(18px);

  a {
    font-weight: bold;
    color: $white1;
  }

  span {
    position: absolute;
    right: 16px;
    line-height: 24px;
    font-size: 30px;
    cursor: pointer;
  }
}
</style>
